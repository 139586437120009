<template>
  <v-snackbar
    v-model="mValue"
    :class="level"
    :timeout="timeout"
    location="top"
    multi-line
    vertical
  >
    <div class="d-flex w-100">
      <div class="pr-1">
        <IconExclamationCircleLine v-if="isError" class="left-icon" />
      </div>
      <div class="message">
        {{ message }}
      </div>
      <div class="ms-auto">
        <v-btn
          class="close"
          density="compact"
          :icon="IconSnackbarCross"
          variant="plain"
          @click="closeSnackbar"
        ></v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script setup>
import IconExclamationCircleLine from '~/assets/images/icon/icon-exclamation-circle-line.svg?component'
import IconSnackbarCross from '~/assets/images/icon/icon-snackbar-cross.svg?component'

const props = defineProps({
  modelValue: Boolean,
  level: {
    type: String,
    default: 'info',
    validator: (value) =>
      ['success', 'info', 'warning', 'error'].includes(value),
  },
  message: {
    type: String,
    required: true,
  },
  timeout: {
    type: Number,
    default: -1,
  },
})

const emit = defineEmits(['update:modelValue', 'close'])

const mValue = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  },
})

const isSuccess = computed(() => props.level === 'success')
const isInfo = computed(() => props.level === 'info')
const isWarning = computed(() => props.level === 'warning')
const isError = computed(() => props.level === 'error')

const closeSnackbar = () => {
  emit('close')
}
</script>

<style scoped lang="sass">
.error
  .close
    width: 2em
    height: 2em
    :deep(path)
      fill: #FF0000 !important
  .message
    padding:5px
    font-size: 1.2em
  .left-icon
    width: 2.5em
    height: 2.5em
    :deep(path)
      fill: #FF0000 !important
  :deep(.v-snackbar__wrapper)
    border: 1px solid #C55457
    background-color: #fcdddd
    .v-snackbar__content
      width: 100%
      color: #FF0000
</style>
